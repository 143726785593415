import * as d3 from "d3";
import { toPascalCase } from "js-convert-case";
import { parse as uuidParse, Uuid, stringify as uuidStringify } from "uuid";

const WIDTH = 1000;
const HEIGHT = 1000;

enum InvocationStatus {
  Pending,
  Started,
  Succeeded,
  Failed,
  Panicked,
  TimedOut,
  Stopped,
}

const WORKER_URL = "https://api.merkuriusze.pl";

async function loadTree(uuid: Uuid): Promise<void> {
  const response = await fetch(`${WORKER_URL}/tree/${uuidStringify(uuid)}`);
  const data = await response.json();

  if (data["source_id"] != null) {
    var a = document.createElement("a");
    a.href = "/#" + data["source_id"];
    a.innerText = "Parent task";
    document.body.appendChild(a);
  }

  const root = d3.hierarchy(data);
  const links = root.links();
  const nodes = root.descendants();

  const drag = (simulation) => {
    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }
    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }
    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }
    return d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };

  const simulation = d3
    .forceSimulation(nodes)
    .force(
      "link",
      d3
        .forceLink(links)
        .id((d) => d.id)
        .distance(0)
        .strength(1)
    )
    .force("charge", d3.forceManyBody().strength(-50))
    .force("x", d3.forceX())
    .force("y", d3.forceY());

  const svg = d3
    .select("body")
    .append("svg")
    .attr("viewBox", [-WIDTH / 2, -HEIGHT / 2, WIDTH, HEIGHT]);

  const link = svg
    .append("g")
    .attr("stroke", "#999")
    .attr("stroke-opacity", 0.6)
    .selectAll("line")
    .data(links)
    .join("line");

  const node = svg
    .append("g")
    .attr("fill", "#fff")
    .attr("stroke", "#000")
    .attr("stroke-width", 1)
    .selectAll("circle")
    .data(nodes)
    .join("circle")
    .attr("fill", (d) => {
      let fill: string;
      let invocationStatus =
        InvocationStatus[
          toPascalCase(d.data.status) as keyof typeof InvocationStatus
        ];
      switch (+invocationStatus) {
        case InvocationStatus.Failed:
          fill = "crimson";
          break;
        case InvocationStatus.Succeeded:
          fill = "green";
          break;
        case InvocationStatus.Panicked:
          fill = "darkorange";
          break;
        case InvocationStatus.Pending:
          fill = "white";
          break;
        case InvocationStatus.Started:
          fill = "lightskyblue";
          break;
        case InvocationStatus.Stopped:
          fill = "black";
          break;
        case InvocationStatus.TimedOut:
          fill = "darkslateblue";
          break;
      }
      return fill;
    })
    .attr("r", 4)
    .on("click", (_e, d) => {
      window.location.hash = d.data.id;
    })
    .style("cursor", "pointer")
    .call(drag(simulation));

  node.append("title").text((d) => d.data.name);

  simulation.on("tick", () => {
    link
      .attr("x1", (d) => d.source.x)
      .attr("y1", (d) => d.source.y)
      .attr("x2", (d) => d.target.x)
      .attr("y2", (d) => d.target.y);
    node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
  });
}

async function loadIndex(): Promise<void> {
  const response = await fetch(`${WORKER_URL}/roots`);
  const data = await response.json();

  for (var elem of data) {
    var link = document.createElement("a");
    link.href = `/#${elem.id}`;
    link.innerText = `${elem.function_name} [${elem.status}]`;
    document.body.appendChild(link);
  }
}

async function main(): Promise<void> {
  const uuidString = window.location.hash.replace(/^#/, "");
  if (uuidString.length == 0) {
    await loadIndex();
  } else {
    const uuid = uuidParse(uuidString);
    await loadTree(uuid);
  }
}

window.onload = () => {
  main()
    .then((text) => console.log(text))
    .catch((error) => {
      console.error(error);
    });

  addEventListener("hashchange", (_event) => {
    document.body.innerHTML = "";
    main()
      .then((text) => console.log(text))
      .catch((error) => {
        console.error(error);
      });
  });
};
